import { axiosInstance } from 'src/utils/axios'
import { productServiceBaseUrl, socialServiceBaseUrl, componentServiceBaseUrl } from 'src/config'
import { logging } from 'src/utils/logging'
import { normalizeCategories, normalizeProducts } from 'src/contexts/helpers'
import { ProductItemFull, ProductPrimitive } from 'src/components/ProductSelectorLarge'
import { Category, CategoryPrimitive,  Post, PostsResponse, PrependShop } from 'src/contexts/types'
import { AxiosResponse } from 'src/types/axios'

export type UploadStorefrontImage = (file: File) => Promise<AxiosResponse<{ url: string }> | undefined>
export type UploadStorefrontImageApi = PrependShop<UploadStorefrontImage>

export const uploadStorefrontImageAPI: UploadStorefrontImageApi = async (activeShop: string, file: File) => {
  try {
    const url = '/v1/components/media'

    const payload = new FormData()
    payload.append('media', file)

    const response = await axiosInstance.post(url, payload, {
      baseURL: componentServiceBaseUrl,
      headers: {
        'shop': activeShop,
      },
    })
    return response
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'campaign image upload' } })
  }
}

export type GetPost = (accountId: string, postId: string) => Promise<AxiosResponse<Post> | undefined>
export type GetPostApi = PrependShop<GetPost>

export const getPostApi: GetPostApi = async (activeShop: string, accountId: string, postId: string) => {
  try {
    const url = `/v1/instagram/${accountId}/posts/${postId}`

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      shop: activeShop,
    }

    const response = await axiosInstance.get<Post>(url, {
      baseURL: socialServiceBaseUrl,
      headers,
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get posts' } })
  }
}

export type GetPosts = (accountId: string, limit?: number, offset?: number, search?: string) => Promise<AxiosResponse<PostsResponse> | undefined>
export type GetPostsApi = PrependShop<GetPosts>

export const getPostsApi: GetPostsApi = async (activeShop: string, accountId: string, limit?: number, offset?: number, search?: string) => {
  try {
    const url = `/v1/instagram/${accountId}/posts?offset=${offset || 0}&limit=${limit || 10}${search ? `&search=${search}` : ''}`

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      shop: activeShop,
    }

    const response = await axiosInstance.get<PostsResponse>(url, {
      baseURL: socialServiceBaseUrl,
      headers,
    })
    return response
  }
  catch (error) {
    logging(error, { tags: { section: 'get products by id' } })
  }
}
export type GetProductsByIds = (productIds?: string[], sameCategory?: boolean, searchTerm?: string) => Promise<ProductItemFull[] | undefined>
export type GetProductsByIdsApi = PrependShop<GetProductsByIds>

export const getProductsByIdsApi: GetProductsByIdsApi = async (activeShop: string, productIds: string[], sameCategory?: boolean) => {
  try {
    let url = '/v1/products/primitives'
    if (productIds.length > 0) {url += `?productIds=${productIds.toString()}`}
    if (sameCategory) {url += `&filterBy=SAME_CATEGORY`}

    const response = await axiosInstance.get<ProductPrimitive[]>(
      url,
      {
        baseURL: productServiceBaseUrl,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'shop': activeShop },
      },
    )


    const productItemsNotInOrder = response.data.map(normalizeProducts)
    if (productIds.length > 0) {
      const productDict: { [productId: string]: ProductItemFull } = {}
      productItemsNotInOrder.forEach(productItem => { productDict[productItem.productId] = productItem })
      return productIds.map(id => productDict[id])
    }
    else {
      return productItemsNotInOrder
    }
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get products by id' } })
  }
}

export type SearchProducts = (searchTerm?: string, limit?: number, offset?: number, blacklistedProductIds?: string[]) => Promise<ProductItemFull[] | undefined>
export type SearchProductsApi = PrependShop<SearchProducts>

// Function that searches for products based on the search term or retrieves the first 10 products
export const searchProductsApi: SearchProductsApi= async (activeShop: string, searchTerm: string, limit: number, offset: number, blacklistedProductIds: string[] = []) => {
  try {
    let url = '/v1/products/primitives'

    if (searchTerm) url += `?search=${searchTerm}`
    else url += `?sortBy=TITLE`
    url += `&offset=${offset || 0}&limit=${limit || 10}`

    if (blacklistedProductIds.length)
      url += `&blacklistedProductIds=${blacklistedProductIds.join(',')}`

    const response = await axiosInstance.get<ProductPrimitive[]>(
      url,
      {
        baseURL: productServiceBaseUrl,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'shop': activeShop },
      },
    )
    return response.data.map(normalizeProducts)
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'search products' } })
  }
}


export type GetCategories = (categoryIds: string[]) => Promise<Category[] | undefined>
export type GetCategoriesApi = PrependShop<GetCategories>

export const getCategoriesApi: GetCategoriesApi = async (activeShop: string, categoryIds: string[]) => {
  try {
    let url = '/v1/categories/primitives'
    if (categoryIds.length > 0) url += `?categoryIds=${categoryIds.toString()}`

    const response =
      await axiosInstance.get<CategoryPrimitive[]>(
        url,
        {
          baseURL: productServiceBaseUrl,
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'shop': activeShop },
        },
      )

    return response.data.map(normalizeCategories)
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'get categories' } })
  }
}

export type SearchCategories = (searchTerm?: string, limit?: number, offset?: number) => Promise<Category[] | undefined>
export type SearchCategoriesApi = PrependShop<SearchCategories>

// Function that searches for categories based on the search term or retrieves the first 10 categories
export const searchCategoriesApi: SearchCategoriesApi= async (activeShop: string, searchTerm: string, limit: number, offset: number) => {
  try {
    let url = '/v1/categories/primitives'
    if (searchTerm) {
      url += `?search=${searchTerm}&offset=${offset || 0}&limit=${limit || 10}`
    } else {
      url += `?offset=${offset || 0}&limit=${limit || 10}`
    }

    const response = await axiosInstance.get<CategoryPrimitive[]>(
      url,
      {
        baseURL: productServiceBaseUrl,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'shop': activeShop },
      },
    )
    return response.data.map(normalizeCategories)
  } catch (error) {
    console.log({ error })
    logging(error, { tags: { section: 'search categories' } })
  }
}
